import { lazy, Suspense } from 'react';
import {Route, Routes } from 'react-router-dom';

import ReqeustError from "../../pages/ReqeustError";

const Home = lazy(() => import("../../pages/Home"))
const EntryDoors = lazy(() => import("../../pages/EntryDoors"))
const InteriorDoors = lazy(() => import("../../pages/InteriorDoors"))
const GlassPartitions = lazy(() => import("../../pages/GlassPartitions"))
const Flooring = lazy(() => import("../../pages/Flooring"))
const HardWare = lazy(() => import("../../pages/HardWare"))
const Portfolio = lazy(() => import("../../pages/Portfolio"))
const Contact = lazy(() => import("../../pages/Contact"))

function NavigationPages() : JSX.Element {
    return (
      <main>
          <Routes>
            <Route path='/' element={<Suspense><Home /></Suspense>}/>
            <Route path='/about' element={<Suspense><Portfolio /></Suspense>}/>
            <Route path='/contact' element={<Suspense><Contact /></Suspense>}/>
            <Route path='/entry-doors' element={<Suspense><EntryDoors /></Suspense>}/>
            <Route path='/interior-doors' element={<Suspense><InteriorDoors /></Suspense>}/>
            <Route path='/glass-partitions' element={<Suspense><GlassPartitions /></Suspense>}/>
            <Route path='/flooring' element={<Suspense><Flooring /></Suspense>}/>
            <Route path='/hardware' element={<Suspense><HardWare /></Suspense>}/>
            <Route path='*' element={<ReqeustError />}/>
          </Routes>
      </main>
    );
  }

export default NavigationPages