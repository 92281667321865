import { ContactDataInterface, contactData } from "./ContactData";
import { Image, ImageStyles } from "../image";
import { Text, TextStyles } from "../text";
import Axios from "axios";
import { useState, useEffect } from "react";
import { mainApi } from "../../config";
import NavigationManager from "../../managers/NavigationManager";

function ContactBlock() {


  const [data, setData] = useState<{[name: string]: ContactDataInterface}>(contactData);

  const apiUrl = mainApi  + 'kontaktni-danni';

  const fetchData = async () => {
    try {
      const response = await Axios.get(apiUrl);

      const container = response.data.data.attributes

      contactData['location'].text = container.location
      contactData['email'].text = container.email
      contactData['phone'].text = container.phone
      contactData['workdays'].text = container.workdays

      setData(contactData);

    } catch (error) {
      NavigationManager('/serverconnection');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  
  return (
    <div className='grid mx-6 my-0 pb-20 md:my-12 md:pb-10 gap-y-6 justify-center md:justify-normal'>
      {getData(data)} 
    </div>
  );
}

function getData(data : {[name: string]: ContactDataInterface}) {

  return Object.keys(data).map((key) => (
    <div className='flex items-center w-full h-14' key={key}>
      <meta name="description" content={data['location'].text  + "|" + data['email'].text + "|" + data['phone'].text + "|" + contactData['workdays'].text} />
      {getImage(contactData[key].icon)}
      <div>{getText(data[key].text)}</div>
    </div>
  ));
}

function getImage(icon : string) {
  return <Image path={icon} key={icon} imageStyle={ImageStyles.contactBlock} />;
}

function getText(lines : string) {
  return (<Text textType="H" text={lines} textStyle={TextStyles.contactText}/>);
}

export default ContactBlock;
