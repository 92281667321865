const ImageStyles = {
    dropdownIco : "relative ml-0 mt-1 scale-y-[-1] ml-2 md:w-2 xl:w-4",
    smalldropdownIco : "flex-none w-2 h-auto translate-x-[4px] mt-1 scale-y-[-1]",
    intro : "w-full h-full object-cover object-top",
    header : `h-auto shadow-xl z-1 md:w-52 xl:w-80`,
    headerSmall : 'w-36 h-auto z-1',
    galleryImage: "transition-all duration-200 ease-in-out object-cover object-center w-full h-full",
    contactIntro: "object-cover w-full h-full",
    partnerIco: "w-24 h-auto md:w-40",
    headerPage: "w-full h-96 object-cover z-[-1]",
    categoryIco: "w-full h-auto md:w-full md:h-full object-cover shadow-xl",
    categoryPartnerIco: "h-8 bg-[#fff] mx-4 md:mx-16 md:h-14",
    closeIco: "w-full h-full",
    arrowImage: "w-4 h-4",
    popupImage: "relative h-auto mt-12 md:max-h-[25rem] xl:max-h-[40rem]",
    loopImage: "w-6 h-auto rotate-45",
    contactPageIco: "scale-[0.5] md:scale-[0.5] xl:scale-[0.7] ",
    contactPageBig : "w-72 h-72 object-cover object-center md:w-96",
    contactBlock : "scale-[1] md:scale-[0.8] xl:scale-[1]",
    chooseUsIco: "w-auto h-14 md:h-14 xl:h-24",
    socialIco: "md:w-9 xl:w-auto xl:h-auto",
    socialSmallIco: "",
    categoryIcoCotained: "w-full h-auto xl:max-w-sm",
}

export {ImageStyles}

//md:w-72 md:h-52 xl:w-96 xl:h-72 object-contain