import React from 'react';
import { Link } from 'react-router-dom';
import { Text } from '../../../text';

export interface MenuButtonInterface {
  key?: string;
  path: string;
  text: string;
  style: string;
  onClick?: () => void; // Додаємо onClick як необов'язкову функцію
}

function MenuButton(props: MenuButtonInterface): React.JSX.Element {
  const { path, text, style, onClick } = props;

  return (
    <li onClick={onClick}>
      <Link to={path}>
        <Text textType="H" text={text} textStyle={style} />
      </Link>
    </li>
  );
}

export default MenuButton;
