import React from 'react';
import './App.css';
import Header from './feature/header/Header';
import NavigationPages from './feature/navigator/NavigationPages';
import Footer from './feature/footer/Footer';
import { BrowserRouter } from 'react-router-dom';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

function App() {

  return (
      <div>
        <BrowserRouter>
          <div className="App">
            <Header />
            <NavigationPages />
            <LazyLoadComponent><Footer /></LazyLoadComponent>
          </div>
        </BrowserRouter>
      </div>
  );
}

export default App;