import { imagePath } from '../../config';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export interface ImageProps {
  key?: string;
  path: string;
  imageStyle?: string;
  loading?:  "eager" | "lazy"
  width?:number
  height?:number
}

export default function Image(props: ImageProps) {
  return (
    <img
      src={imagePath + props.path}
      alt={props.path}
      className={props.imageStyle}
    />
  );
}

export function LazyImage(props: ImageProps) {
  return (
    <LazyLoadImage
      src={imagePath + props.path}
      alt={props.path}
      className={props.imageStyle}
      width={props.width}
      height={props.height
      }
      wrapperProps = { { 
        style : { conversionDelay : " 1s" } as React.CSSProperties, 
      } } 
    />
  );
}