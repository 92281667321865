import React from 'react';
import useStickyScroll from '../../managers/useStickyScroll';
import { MenuList } from '../../components/menulist';
import { links } from './MenuConfig';

let isSticky;

function Menu() {
  isSticky = useStickyScroll();

  // Обробник для закриття меню при виборі пункту
  const handleItemClick = () => {
    console.log('Menu item clicked');
    // Тут можна додати додаткову логіку, якщо потрібно
  };

  return (
    <nav className={`bg-white border mb-5 shadow-2xl items-center w-min ${isSticky ? 'fixed w-full index-y-0 top-0 xl:py-8 md:py-2' : 'xl:py-8 md:py-5'}`}>
      <ul className={`menu flex space-x-20 translate-y-5 justify-center px-24 ${isSticky ? 'translate-y-[0] justify-center' : ''}`}>
        <MenuList array={links} onItemClick={handleItemClick} />
      </ul>
    </nav>
  );
}

export default Menu;
