import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import * as rdd from 'react-device-detect';

// rdd.isMobile = true;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<Profiler id="App" onRender={onRenderCallback}>
      <App />
  //</Profiler>,
  //document.getElementById('root')
  // <Router>
  //   <App />
  // </Router>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
